import React from 'react';
import { Box, Typography } from '@mui/material';
import Link from '../components/Link';

const Custom404 = () => (
  <Box
    textAlign={{ sm: 'center' }}
    maxWidth={600}
    display='flex'
    flexDirection='column'
    justifyContent='center'
    alignContent='center'
    minHeight='80vh'
    m={{ xs: 4, sm: '0 auto' }}
  >
    <Typography variant='h1'>
      <span role='img' aria-label='man shrugging'>
        🤷‍♂️
      </span>
    </Typography>
    <Typography variant='h2'>Something went wrong.</Typography>
    <Typography variant='body1' color='textSecondary'>
      It’s us, not you. That link was broken. And now our hearts are, too. Let’s get you
      <span> </span>
      <Link href='/' underline='always' external>
        home
      </Link>
      .
    </Typography>
  </Box>
);

export async function getStaticProps() {
  return {
    props: {},
  };
}

export default Custom404;
